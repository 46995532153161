import axios from "axios";
import router from "@/router";
import store from "@/store";
import { Message, Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import _ from "lodash";

const instance = axios.create({
  timeout: 20000,
  baseURL: process.env.VUE_APP_API_ROOT,
});

let httpCode = {
  400: "參數錯誤",
  401: "權限不足",
  403: "伺服器拒絕請求",
  404: "請求資源未找到",
  500: "內部伺服器錯誤",
  501: "伺服器不支援此請求中使用的方法",
  502: "網路閘道錯誤",
  504: "網路閘道超時",
};

let loading;
let needLoadingRequestCount = 0;

function showLoading(target) {
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Loading.service({
      lock: true,
      text: "Loading...",
      background: "rgba(255, 255, 255, 0.5)",
      target: target || "body",
    });
  }
  needLoadingRequestCount++;
}

function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0);
  if (needLoadingRequestCount === 0) {
    toHideLoading();
  }
}

var toHideLoading = _.debounce(() => {
  if (loading != null) {
    loading.close();
  }
  loading = null;
}, 300);

instance.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      config.params = {
        ...config.params,
        t: new Date().getTime(),
      };
    }
    if (config.headers.showLoading !== false) {
      showLoading(config.headers.loadingTarget);
    }
    if (config.headers.requiresAuth !== false) {
      if (store.state.token) {
        config.headers.token = store.state.token;
      } else {
        router.push({
          path: `/login`,
        });
      }
    }
    return config;
  },
  (error) => {
    if (config.headers.showLoading !== false) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.config.headers.showLoading !== false) {
      hideLoading();
    }

    if (response.data.code == 200) {
      return Promise.resolve(response);
    }

    if (response.data.code == 5000) {
      Message.error("登入已失效，請重新登入");
      localStorage.clear();
      router.push({
        path: `/login`,
      });
    }

    if (response.config.responseType == "blob" || response.status == 200) {
      return Promise.resolve(response);
    } else {
      Message.error(`[${response.data.code}]: ${response.data.message}`);
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response) {
      if (error.config.headers.showLoading !== false) {
        hideLoading();
      }

      let tips =
        error.response.status in httpCode
          ? httpCode[error.response.status]
          : error.response.data.error;
      Message.error(
        `[${error.response.status}]: ${error.response.data.message}`
      );
      return Promise.reject(error);
    } else {
      if (error.config.headers.showLoading !== false) {
        hideLoading();
      }
      Message.error("請求超時，請重新再試");
      return Promise.reject(error);
    }
  }
);

export function get(url, params, config = {}) {
  return new Promise((resolve, reject) => {
    instance({
      method: "get",
      url,
      params,
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function post(url, data, config = {}) {
  return new Promise((resolve, reject) => {
    instance({
      method: "post",
      url,
      data,
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
